h1.hire__text{
    font-size: 40px;
}
p.hire__text{
    font-size: 15px;
    line-height: 30px;
    color: black;
    font-weight: 400;
}
input.contact{
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0,0,0,-1);
    border-radius: 5px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    height: 50px;
    line-height:46px;
    margin: 20px 0px;
    outline:none;
    color: rgb(0, 0, 0);
    font-size:15px;
    letter-spacing: .1px;
    background: none;
    border: 2px solid rgba(0, 0, 0, 0.2);

}

textarea#message{
    height: 120px;
    background:none;
    padding:0 20px;
    width: 100%;
    line-height: 46px;
    color: #fff;
    font-size: 15px;
    letter-spacing: .1px;
    border: 2px solid hsla(0,0%,100%,.2);

}

.button.btn.contact.pointer {
    margin:20px 0px;
}
.button.about.btn.pointer:hover {
    background: #fff;
    color: #000!important;
  }

.button.btn {
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    border: 2px solid #f9004d;
    background-color: #f9004d;
    color: #fff!important;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: .4s all ease;
    height: 50px;
    width: 180px;
    margin: 0px 10px;
  }