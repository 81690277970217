.h1,h2,h3,h4,h5,h6,span{
    font-size: 18px;
    color: rgb(0, 0, 0);
    font-family: Poppins,sans-serif;
  }

 
  @media only screen and (max-width: 768px){
    .col__2 {
        max-width: 100%;
    }
    }
    @media only screen and (max-width: 768px){
    .col__3 {
        max-width: 50%;
    }
    }
    @media only screen and (max-width: 425px){
    .col__3 {
        max-width: 100%;
    }
    }
    @media only screen and (max-width: 425px){
    span.copyright {
        font-size: 13px!important;
    }
    .footer.d__flex.align__items__center.justify__content__space__between.pz-10 {
      flex-wrap: wrap;
    }
    }
    
    
    
  